<template>
    <div class="c-detail">
      <div v-if="isEndLoading && Object.keys(detail).length">
        <div>
          <div class="h-title">
            <span>{{detail.name}}</span>
          </div>
          <div>
            <el-tag :type="tag.type" v-for="(tag, index) in $formatQuality(detail.qualifications)" :key="index" style="margin-right: 5px">
              {{tag.text}}
            </el-tag>
          </div>
        </div>
        <el-card  class="box-card" style="margin-top: 10px" :body-style="{
          padding: activeName === 'ability' ? '5px' : ''
        }">
          <div slot="header" class="clearfix">
            <el-tabs v-model="activeName" >
              <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.label" :name="item.name"></el-tab-pane>
            </el-tabs>
          </div>
          <div v-show="activeName === 'index'">
            <el-descriptions class="margin-top"  :column="1" border :labelStyle="{
                      width: '120px'
                    }" >
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  机构联系人
                </template>
                {{detail.contact}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  电话号码
                </template>
                {{detail.phone}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  地址
                </template>
                {{detail.address}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-warning-outline"></i>
                  特殊说明
                </template>
                {{detail.description}}
              </el-descriptions-item>
            </el-descriptions>
            <el-row class="collect">
              <el-col :span="24">
                <i @click="setCollect" class="iconfont  icon-jushoucanggift" v-if="isCollect"></i>
                <i @click="setCollect" class="iconfont icon-shoucang" v-else ></i>

              </el-col>
              <el-col :span="24" v-if="collCount > 0">
                <span class="coll-num">{{collCount}}人已收藏</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="24">
                <div style="width: 100%;overflow: auto">
                  <div class="img-block" v-for="item in detail.certificate_imgs || []" :key="item.url">
                    <el-image fit="cover" width="150" :preview-src-list="detail.certificate_imgs.map(img => img.url)" :src="item.url"></el-image>
                    <!--                                                    <div class="file-name">-->
                    <!--                                                      {{item.filename.split('.')[0]}}-->
                    <!--                                                    </div>-->
                  </div>

                </div>
                <!--                    <el-carousel indicator-position="outside">-->
                <!--                      <el-carousel-item v-for="item in detail.certificate_imgs || []" :key="item.url">-->
                <!--                        <div class="img-block">-->
                <!--                          <el-image fit="cover" :preview-src-list="detail.certificate_imgs.map(img => `https://admin.jya360.com/`  + img.url)" :src="`https://admin.jya360.com/` + item.url"></el-image>-->
                <!--&lt;!&ndash;                          <div class="file-name">&ndash;&gt;-->
                <!--&lt;!&ndash;                            {{item.filename.split('.')[0]}}&ndash;&gt;-->
                <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
                <!--                        </div>-->
                <!--                      </el-carousel-item>-->
                <!--                    </el-carousel>-->
              </el-col>
<!--              <el-col :span="10">-->
<!--                <div id="map" @click="clickMap"></div>-->
<!--              </el-col>-->
            </el-row>

          </div>
          <div v-show="activeName === 'image'">
            <el-col :span="6" v-for="(imgItem, index) in detail.imgs" :key="index">
              <el-card class="box-card" :body-style="{ padding: '0px' }" style="margin: 5px 10px">
                <el-image style="width: 100%;height: 240px" :src='`${imgItem.url}`'  class="image"/>
<!--                <el-image style="width: 100%;height: 240px" :src='`https://jya360.com/${imgItem}`'  class="image"/>-->
                <!--                <img style="width: 100%" src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">-->
                <div style="padding: 14px;text-align: center">
                  <span>{{imgItem.filename.split('.')[0]}}</span>
                </div>
              </el-card>
            </el-col>
          </div>
          <div v-show="activeName === 'introduce'">
            <div v-html="detail.introduce"></div>
          </div>
          <div v-show="activeName === 'ability'">
            <list-1 :cid="cid" :formDetailRead="formDetailRead"/>
          </div>
        </el-card>
        <el-divider content-position="center"><span style="font-size: 18px;font-weight: bold">实验机构推荐</span></el-divider>
        <el-col :span="6" v-for="(o, index) in recommendList_1" :key="index">
          <el-card class="box-card" :body-style="{ padding: '0px' }" style="margin: 5px 10px">
            <el-image style="width: 100%;height: 174px" :src='o.image'  class="image" />
            <!--                <img style="width: 100%" src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">-->
            <div style="padding: 14px;">
              <span>{{o.name}}</span>
              <div class="bottom clearfix">
                <time class="time">{{o.province}}/{{ o.city }}</time>
                <el-button type="text" class="button">查看详情</el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </div>
      <div v-if="isEndLoading && !Object.keys(detail).length">
        <el-empty description="当前实验室可能没认证或未收录数据">
          <el-button type="text" @click="goList">去看看更多实验室>></el-button>
        </el-empty>
      </div>

    </div>
</template>

<script>
    import { channelService } from '../../api/channel'
    import { archivesService } from '../../api/archives'
    import List1 from "../list/child/list_1";
    export default {
        name: "detail-index",
        components: {List1},
        data() {
            return {
                collCount: 0,
                activeName: 'index',
                isCollect: false,
                isEndLoading: false,
                formDetailRead: false,
                tabs: [
                    {
                        label: '基本信息',
                        name: 'index'
                    },
                    {
                        label: '机构图集',
                        name: 'image'
                    },
                    {
                        label: '机构介绍',
                        name: 'introduce'
                    },
                    {
                        label: '能力列表',
                        name: 'ability'
                    }
                ],
                detail: {},
                cid: '',
                recommendList_1: [],
            }
        },
        methods: {
            goList() {
              this.$router.replace('/list/laboratory')
            },
            clickMap() {
              window.open(`https://uri.amap.com/marker?position=${this.detail.longitude},${this.detail.latitude}`)
            },
            getCollCount(isInit) {
                archivesService.collectCount({
                    id: this.cid,
                    type: 'channel'
                }).then(res => {
                    // console.log(res);
                    this.collCount = res.data
                    if (!isInit) {
                        this.isCollect = !this.isCollect
                    }
                })
            },
            setCollect() {
                const api = this.isCollect? archivesService.removeCollect: archivesService.collection
                api({
                    aid: this.cid,
                    id: this.cid,
                    type: 'channel',
                    title: this.detail.name
                }).then(res => {
                    // // console.log('res', res)
                    if (res.code == 1) {
                        this.$message.success(res.msg)
                        this.$store.dispatch('getCollList')
                        this.getCollCount()
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },
            initMap() {
              const center  = [this.detail.longitude, this.detail.latitude]
              const map = new AMap.Map('map', {
                resizeEnable: true,
                zoom:14,
                center
              });
              // 构造点标记
              var marker = new AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
                position: center
              });
              map.add(marker);
              var info = [];
              info.push("<div class='input-card content-window-card'><div></div> ");
              info.push(`<div style="padding:7px 0 0 0;"><h4>${this.detail.name}</h4>`);
              info.push(`<p class='input-item'>电话 : ${this.detail.phone}   联系人 : ${this.detail.contact}</p>`);
              info.push(`<p class='input-item'>地址 :${this.detail.address}</p></div></div>`);

              const infoWindow = new AMap.InfoWindow({
                content: info.join("")  //使用默认信息窗体框样式，显示信息内容
              });

              infoWindow.open(map, map.getCenter());
            },
            getDetail() {
                this.cid = this.$route.query.id
                if (!this.cid) {
                      this.$message.error('获取数据失败')
                      return
                }
                this.isEndLoading = false
                this.getCollCount(true)
                channelService.detail({cid: this.cid, views: true}).then(res => {
                  // // console.log('res', res)
                    const data = res.data
                    this.isCollect = data.isCollect
                    if (data.images) {
                        data.images = data.images.split(',')
                    } else {
                        data.images = []
                    }
                    this.detail = data
                    this.$store.dispatch('getRecordList')
                    this.initMap()
                    channelService.recommend({cid: data.cid}).then(res => {
                      // console.log('res', res)
                      this.recommendList_1 = res.data
                    })
                }).finally(() => {
                  this.isEndLoading = true
                })
           }
        },
        created() {
            this.getDetail()
        },
        watch: {
            $route: {
                deep: true,
                handler(val) {
                    this.getDetail()
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            // // console.log(3333)
            next(vm => {
              if (from.name === 'detailRead') {
                vm.activeName = 'ability'
              }
              vm.formDetailRead = from.name === 'detailRead'
            })
        },
        mounted() {

        }
    }
</script>
<style  lang="scss">
  .c-detail{

    .el-card__body{
      padding: 10px 20px 20px 20px;
    }
    .custom-input-card{
      width: 18rem;
    }

    .custom-input-card .btn:last-child{
      margin-left: 1rem;
    }

    .content-window-card{
      position: relative;
      width: 250px;
      /*padding: 0.75rem 0 0 1.25rem;*/
      box-shadow: none;
      bottom: 0;
      left: 0;
      h4{
        margin: 5px 0;
      }
    }

    .content-window-card p{
      /*height: 2rem;*/
      margin: 5px 0;
      font-size: 14px;
      color: $font_2;
    }
    .channel_row{
      height: 350px;
      .el-col{
        height: 100%;
        .list-index{
          display: inline-block;
          background: 0 0;
          color: #9195a3;
          width: 18px;
          font-size: 15px;
          letter-spacing: -1px;

        }
        .address{
          font-size: 12px;
          color: $font_2;
        }
        .hot_0{
          color: #fe2d46;
        }
        .hot_1{
          color: #f60;
        }
        .hot_2{
          color: #faa90e;
        }
        .bottom_title {
          font-weight: 400;
          font-size: 16px;
          font-family: Microsoft YaHei;
          margin-bottom: 10px;
          color: #626675;
        }
        .bottom_list {
          cursor: pointer;
          font-weight: 400;
          color: #222;
          font-size: 15px;
          padding: 4px 0;
          font-family: Microsoft YaHei;
        }
      }

    }
  }
</style>
<style scoped lang="scss">

    .c-detail{

        width: 85%;
        /*height: 100%;*/
        margin: 0 auto;
        /*.el-table{*/
        /*    min-height:490px;*/
        /*}*/

        .h-title{
            margin: 10px 0;
            span:nth-child(1) {
                font-size: 30px;
                font-weight: bold;
            }
            span:nth-child(2) {
                color: $font_2;
                font-size: 13px;
            }
        }
      .center-card, .right-card{
        margin: 5px 0 0 5px;
        /*height: 300px;*/
      }
        #map, .img-block{
            width: 100%;
            //margin: 10px 0;
            height: 300px;
        }
        .img-block{
            position: relative;
            text-align: center;
            width: 20%;
            display: inline-flex;
            /*margin-right: 15px;*/
          .el-image{
            height: 100%;
            /*width: 25%;*/
          }
          .file-name{
            position: absolute;
          }
        }
        .left-card{
            margin-top: 5px;
          h3{
            margin: unset;
          }
          .description{
            margin: 5px 0;
            min-height: 1px;
            color: $font_1;
          }

        }
        .box-card{
            height: 100%;
            overflow: auto;

            .collect{
                margin-top: 20px;
                text-align: center;
                .coll-num{
                    font-size: 12px;
                    color: $font_3;
                }
                i{
                    cursor: pointer;
                    font-size: 30px;
                }
            }

        }
    }
</style>
